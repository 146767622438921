import React, { useEffect, useState } from "react";
import SliderContent from "./SliderContent";
import Dots from "./Dots";
import sliderImageTwo from "./sliderImageTwo";

import classes from "./BrandsSlide.module.css";

const len = sliderImageTwo.length - 1;

const SmallScreenBrandsSlide = () => {
  const [activeIndex, setActiveIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setActiveIndex(activeIndex === len ? 0 : activeIndex + 1);
    }, 5000);
    return () => clearInterval(interval);
  }, [activeIndex]);

  return (
    <div className={classes.container}>
      <div className={classes.content}>
        <h1>A Few Of Our Clients</h1>
        <hr />
      </div>
      <div className={classes["slider-container"]} id="Slider">
        <SliderContent activeIndex={activeIndex} sliderImage={sliderImageTwo} />
        <Dots
          activeIndex={activeIndex}
          sliderImage={sliderImageTwo}
          onclick={(activeIndex) => setActiveIndex(activeIndex)}
        />
      </div>
    </div>
  );
};

export default SmallScreenBrandsSlide;
