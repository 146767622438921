import First from "../../Images/Nextad Web Brands 01.png";
import Second from "../../Images/Nextad Web Brands 02.png";
import Third from "../../Images/Nextad Web Brands 03.png";
import Fourth from "../../Images/Nextad Web Brands 04.png";
import Fifth from "../../Images/Nextad Web Brands 05.png";

const sliderImage = [
  {
    title: `Logo1`,
    urls: First,
  },
  {
    title: `Logo2`,
    urls: Second,
  },
  {
    title: `Logo3`,
    urls: Third,
  },
  {
    title: `Logo4`,
    urls: Fourth,
  },
  {
    title: `Logo5`,
    urls: Fifth,
  },
];

export default sliderImage;
