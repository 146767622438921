import classes from "./ServiceCard.module.css";

const ServiceCard = (props) => {
  return (
    <div className={classes.container}>
      <h1>{props.title}</h1>
      <p>{props.description}</p>
    </div>
  );
};

export default ServiceCard;
