import ServiceCard from "./ServiceCard";
import classes from "./Service.module.css";

const Service = () => {
  return (
    <div className={classes.serviceContainer}>
      <h1 className={classes.serviceTitle}>Explore Our Services</h1>
      <div className={classes.serContain}>
        <ServiceCard
          title="Digital Marketing"
          description="In the age of digitalisation, next AD has created benchmarks, both in terms of numbers and innovation."
        />
        <ServiceCard
          title="Branding"
          description="At next AD, we help businesses flourish into brands. We give businesses a voice, an identity and a prominent presence that cultivates numbers and success."
        />
        <ServiceCard
          title="Offline Advertising"
          description="The charm of offline advertising is timeless and ever-efficient."
        />
        <ServiceCard
          title="Next AD Productions"
          description="We understand the power of remarkable visualisation and empower it through our production services."
        />
        <ServiceCard
          title="Photography & Videography"
          description="We provide businesses with photography solutions, narrating brand stories through unique shoots."
        />
        <ServiceCard
          title="Brand Podcast"
          description="Using and strategising on the strength of words and sound, we conceptualise, plan, script and create brand podcasts for all types of businesses and industries."
        />
      </div>
    </div>
  );
};

export default Service;
