import { useRef } from "react";
import emailjs from "@emailjs/browser";

import useInput from "../Hooks/use-input";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";

import "./EnquireForm.css";

const isNotEmpty = (value) => value.trim() !== "";
const isEmail = (value) => value.includes("@");
const isNumber = (value) => value.length === 10;

const EnquireForm = (props) => {
  const form = useRef();
  // const {isVerified, setIsVerified} = useState(false);
  
  //Recaptcha Function
  // const onChange = (value) => {
  //   console.log("Captcha Value:", value);
  //   setIsVerified(true);
  // };

  const {
    value: enteredName,
    isValid: enteredNameIsValid,
    hasError: nameInputHasError,
    valueChangeHandler: nameChangeHandler,
    inputBlurHandler: nameBlurHandler,
    reset: resetNameInput,
  } = useInput(isNotEmpty);

  const {
    value: enteredEmail,
    isValid: enteredEmailIsValid,
    hasError: emailInputHasError,
    valueChangeHandler: emailChangeHandler,
    inputBlurHandler: emailBlurHandler,
    reset: resetEmailInput,
  } = useInput(isEmail);

  const {
    value: enteredSubject,
    isValid: enteredSubjectIsValid,
    hasError: subjectInputHasError,
    valueChangeHandler: subjectChangeHandler,
    inputBlurHandler: subjectBlurHandler,
    reset: resetSubjectInput,
  } = useInput(isNotEmpty);

  const {
    value: enteredPhone,
    isValid: enteredPhoneIsValid,
    hasError: phoneInputHasError,
    valueChangeHandler: phoneChangeHandler,
    inputBlurHandler: phoneBlurHandler,
    reset: resetPhoneInput,
  } = useInput(isNumber);

  const {
    value: enteredMessage,
    isValid: enteredMessageIsValid,
    hasError: messageInputHasError,
    valueChangeHandler: messageChangeHandler,
    inputBlurHandler: messageBlurHandler,
    reset: resetMessageInput,
  } = useInput(isNotEmpty);

  let formIsValid = false;

  if (
    enteredNameIsValid &&
    enteredEmailIsValid &&
    enteredSubjectIsValid &&
    enteredPhoneIsValid &&
    enteredMessageIsValid
    // isVerified
  ) {
    formIsValid = true;
  }

  const formSubmitHandler = (event) => {
    event.preventDefault();

    if (
      !enteredNameIsValid &&
      !enteredEmailIsValid &&
      !enteredSubjectIsValid &&
      !enteredPhoneIsValid &&
      !enteredMessageIsValid 
    ) {
      return;
    }

     emailjs
       .sendForm(
         "service_44gtbek",
         "template_qv13fye",
         form.current,
         "5XEq-VBHLb3hHgXOf"
       )
       .then(
         (result) => {
           alert("Form Submitted!!!");
         },
         (error) => {
           console.log(error.text);
         }
       );

    event.target.reset();   
    resetNameInput();
    resetEmailInput();
    resetSubjectInput();
    resetPhoneInput();
    resetMessageInput();
  };

  const nameInputClasses = nameInputHasError
    ? "form-control invalid"
    : "form-control";

  const emailInputClasses = emailInputHasError
    ? "form-control invalid"
    : "form-control";

  const subjectInputClasses = subjectInputHasError
    ? "form-control invalid"
    : "form-control";

  const phoneInputClasses = phoneInputHasError
    ? "form-control invalid"
    : "form-control";

  const messageInputClasses = messageInputHasError
    ? "form-control invalid"
    : "form-control";

  return (
    <form
      onSubmit={formSubmitHandler}
      className="form-container"
      ref={form}
    >
      <div className="formnamenumberemailsubject">
        <div className={nameInputClasses}>
          <input
            type="text"
            id="name"
            name="user_name"
            onChange={nameChangeHandler}
            onBlur={nameBlurHandler}
            value={enteredName}
            placeholder="Name"
            className="inputArea"
          />
          {nameInputHasError && (
            <p className="error-text">Name Must Not Be Empty</p>
          )}
        </div>
        <div className={emailInputClasses}>
          <input
            type="email"
            id="email"
            name="user_email"
            onChange={emailChangeHandler}
            onBlur={emailBlurHandler}
            value={enteredEmail}
            placeholder="E-mail"
            className="inputArea"
          />
          {emailInputHasError && (
            <p className="error-text">Please enter a valid Email</p>
          )}
        </div>
        <div className={subjectInputClasses}>
          <input
            type="text"
            id="Subject"
            name="user_subject"
            onChange={subjectChangeHandler}
            onBlur={subjectBlurHandler}
            value={enteredSubject}
            placeholder="Subject"
            className="inputArea"
          />
          {subjectInputHasError && (
            <p className="error-text">Please enter Subject</p>
          )}
        </div>
        <div className={phoneInputClasses}>
          <input
            type="tel"
            id="phone"
            name="user_phone"
            onChange={phoneChangeHandler}
            onBlur={phoneBlurHandler}
            value={enteredPhone}
            placeholder="Phone"
            className="inputArea"
          />
          {phoneInputHasError && (
            <p className="error-text">Please enter valid Phone Number</p>
          )}
        </div>
      </div>
      <div className={messageInputClasses}>
        <input
          type="text"
          id="Message"
          name="user_message"
          onChange={messageChangeHandler}
          onBlur={messageBlurHandler}
          value={enteredMessage}
          placeholder="Message"
          className="inputAreaMessage"
        />
        {messageInputHasError && (
          <p className="error-text">Please add a Message</p>
        )}
      </div>
      <GoogleReCaptchaProvider
        sitekey="6LeWfV4jAAAAAAcezNJ3rftvBcj8RwGzed3jCqtQ"
        // onChange={onChange}
        className="captcha"
      />
      <div className="form-actions">
        <button
          disabled={!formIsValid}
          className="submitButton"
          type='submit'
        >
          Submit
        </button>
      </div>
    </form>
  );
};

export default EnquireForm;


