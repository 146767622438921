import classes from "./Footer.module.css";

import EnquireForm from "../EnquireForm/EnquireForm";

const Footer = () => {
  return (
    <div className={classes.container}>
      <div className={classes.formContainer}>
        <div className={classes.formText}>
          <h1 className={classes.formTextHeading}>
            Choose nextAD for your next AD
          </h1>
          <div className={classes.lineBreaker}></div>
          <p className={classes.formTextContent}>
            Take your business to the next level with next AD <br />
            You'll require efficient marketing, branding and design or pure
            magic!
            <br />
            Don't worry, we've got you all sorted.
            <br />
          </p>
          <div>
            <a
              href="https://www.facebook.com/nextadco"
              target="_blank"
              rel="noopener noreferrer"
            >
              <picture className={classes.icon}>
                <img
                  src={require("../../Images/Nextad Web FB.png")}
                  alt="NextAd"
                />
              </picture>
            </a>
            <a
              href="https://www.instagram.com/nextadco/?hl=en"
              target="_blank"
              rel="noopener noreferrer"
            >
              <picture className={classes.icon}>
                <img
                  src={require("../../Images/Nextad Web IG.png")}
                  alt="NextAd"
                />
              </picture>
            </a>
            <a
              href="https://www.linkedin.com/company/nextadconsulting/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <picture className={classes.icon}>
                <img
                  src={require("../../Images/Nextad Web LD.png")}
                  alt="NextAd"
                />
              </picture>
            </a>
            <a href="/" target="_blank" rel="noopener noreferrer">
              <picture className={classes.icon}>
                <img
                  src={require("../../Images/Nextad Web TW.png")}
                  alt="NextAd"
                />
              </picture>
            </a>
            <a
              href="https://wa.me/916377405870"
              target="_blank"
              rel="noopener noreferrer"
            >
              <picture className={classes.icon}>
                <img
                  src={require("../../Images/Nextad Web WA.png")}
                  alt="NextAd"
                />
              </picture>
            </a>
          </div>
        </div>
        <div className={classes.formPart}>
          <EnquireForm />
        </div>
      </div>
      <div className={classes.socialFooter}>
        <div className={classes.oneSection}>
          <h4>Services</h4>
          <div className={classes.servicesSection}>
            <div className={classes.serviceType}>
              <div className={classes.serviceSectionOne}>
                <h6>Digital Marketing</h6>
                <h6>Branding</h6>
                <h6>Offline Advertising</h6>
              </div>
            </div>
            <div className={classes.serviceType}>
              <h6>NextAd Productions</h6>
              <h6>Photography & Videography</h6>
              <h6>Brand Podcast</h6>
            </div>
          </div>
        </div>
        <div className={classes.twoSection}>
          <div className={classes.address}>
            <h4>Reach Us</h4>
            <h6>
              203, 2nd Floor <br /> Upasana Ummeed Casablanca <br /> Sodala, New
              Sanganer Road <br /> Jaipur-302006{" "}
            </h6>
          </div>
          <div className={classes.contactInfo}>
            <h4>+91-7877350899</h4>
            <h4>+91-9461114004</h4>
            <h4>hello@nextad.co.in</h4>
          </div>
        </div>
      </div>
      <hr className={classes.breakerLine} />
      <div className={classes.copyright}>
        <div className={classes.rights}>
          <p>All Rights Reserved</p>
          <p>Privacy ▪ Terms & Conditions</p>
        </div>
        <div className={classes.terms}>
          <p>© 2024 - Drama Daily Creative Labs Private Limited</p>
          <p> Made in India</p>
        </div>
      </div>
    </div>
  );
};

export default Footer;
