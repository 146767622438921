import First from "../../Images/Nextad Web Phone Main 01.png";
import Second from "../../Images/Nextad Web Phone Main 02.png";
import Third from "../../Images/Nextad Web Phone Main 03.png";

const imageSlider = [
  {
    urls: First,
  },
  {
    urls: Second,
  },
  {
    urls: Third,
  },
];

export default imageSlider;
