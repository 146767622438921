import classes from "./Careers.module.css";

import CareerCard from "./careerCard";

import { motion } from "framer-motion";

const big = require("../../Images/Nextad-Web-Career.png");
const ipad = require("../../Images/Nextad-Web-Ipad-Career.png");
const phone = require("../../Images/Nextad-Web-Phone-Career.png");

const Careers = (props) => {
  return (
    <motion.div
      className={classes.container}
      intial={{ width: 0 }}
      animate={{ width: "100%" }}
      exit={{ x: window.innerWidth, transition: { duration: 0.3 } }}
    >
      <div className={classes.slideImage}>
        <picture>
          <source media="(max-width: 700px)" srcSet={phone} />
          <source
            media="(max-width: 1100px) and (min-width: 700px)"
            srcSet={ipad}
          />
          <source media="(min-width: 1100px)" srcSet={big} />
          <img src={big} alt="Marketing" />
        </picture>
      </div>
      <div className={classes.introContainer}>
        <h1>First Things First</h1>
        <hr />
        <p>
          We’re a crew of branding, marketing and design experts, on a mission
          to turn great ideas into incredible realities. While no one knows your
          business <br />
          better than you, no one knows your customers better than us! <br />
          <br />
          <br />
          We map out where our clients are and plot a course for where they want
          to be with solutions that are decidedly robust, smartly agile & beyond
          trends. <br />
          <br />
          <br />
          You see the potential. It’s our job to get you there!
        </p>
      </div>
      <div>
        <CareerCard
          image={require("../../Images/Nextad web GD.png")}
          jobRole="Graphic Designer"
        />
        <CareerCard
          image={require("../../Images/Nextad web SMM.png")}
          jobRole="Social Media Manager"
        />
        <CareerCard
          image={require("../../Images/Nextad web SEO Expert.png")}
          jobRole="SEO Expert"
        />
        <CareerCard
          image={require("../../Images/Nextad web Photography Intern.png")}
          jobRole="Photography Intern"
        />
        <CareerCard
          image={require("../../Images/Nextad web Video Editor.png")}
          jobRole="Video Editor"
        />
      </div>
    </motion.div>
  );
};

export default Careers;
