import React from "react";

import classes from './BrandsSlide.module.css';

function Dots({ activeIndex, onclick, sliderImage }) {
  return (
    <div className={classes['all-dots']}>
      {sliderImage.map((slide, index) => (
        <span
          key={index}
          className={`${classes.dot} ${activeIndex === index ?  classes['active-dot'] : classes.dot}`}
          onClick={() => onclick(index)}
        ></span>
      ))}
    </div>
  );
}

export default Dots;
