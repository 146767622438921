import React from "react";

import classes from './Testimonials.module.css';

function SliderContent({ activeIndex, contentTestimonials }) {
  return (
    <section>
      {contentTestimonials.map((slide, index) => (
        <div
          key={index}
          className={`${classes.slides} ${
            index === activeIndex ? classes.active : classes.inactive
          }`}
        >
          <div>
            <img
              className={classes["slide-image"]}
              src={require("../../Images/Nextad Web Testimonials.png")}
              alt="Head"
            />
            <h1>Testimonials</h1>
          </div>
          <div>
            <h2 className={classes["slide-title"]}>{slide.description}</h2>
            <h3 className={classes["slide-text"]}>{slide.writer}</h3>
          </div>
        </div>
      ))}
    </section>
  );
}

export default SliderContent;
