import { useRef } from "react";
import { FaBars, FaTimes } from "react-icons/fa";

import { NavLink } from "react-router-dom";
import "./NavBar.css";

const NavBar = () => {
  const navRef = useRef();

  const showNavBar = () => {
    navRef.current.classList.toggle("responsive_nav");
  };

  return (
    <div className="container">
      <div>
        <a href="http://nextad.co.in/">
          <picture className="logoImage">
            <img
              src={require("../../Images/Nextad Logo web.png")}
              alt="NextAd"
            />
          </picture>
        </a>
      </div>
      <header className="linkContainer">
        <nav className="linker" ref={navRef}>
          <NavLink
            className="navLink"
            to="/"
            // activeClassName="active"
            onClick={showNavBar}
          >
            Home
          </NavLink>
          <NavLink
            className="navLink"
            to="/AboutUs"
            // activeClassName="active"
            onClick={showNavBar}
          >
            About Us
          </NavLink>
          <NavLink
            className="navLink"
            to="/OurServices"
            // activeClassName="active"
            onClick={showNavBar}
          >
            Services
          </NavLink>
          <NavLink
            className="navLink"
            to="/Careers"
            // activeClassName="active"
            onClick={showNavBar}
          >
            Careers
          </NavLink>
          <button className="nav-btn nav-close-btn" onClick={showNavBar}>
            <FaTimes />
          </button>
        </nav>
        <button className="nav-btn" onClick={showNavBar}>
          <FaBars />
        </button>
      </header>
    </div>
  );
};

export default NavBar;
