import RightServiceContainer from "./RightServiceContainer";
import LeftServiceContainer from "./LeftServiceContainer";

import classes from "./OurServices.module.css";
import { motion } from "framer-motion";

const big = require("../../Images/Nextad-Web-Our-Services.png");
const ipad = require("../../Images/Nextad-Web-Ipad-our-Services.png");
const phone = require("../../Images/Nextad-Web-Phone-our-services.png");

const OurServices = () => {
  return (
    <motion.div
      className={classes.container}
      intial={{ width: 0 }}
      animate={{ width: "100%" }}
      exit={{ x: window.innerWidth, transition: { duration: 0.3 } }}
    >
      <div className={classes.slideImage}>
        <picture>
          <source media="(max-width: 700px)" srcSet={phone} />
          <source
            media="(max-width: 1100px) and (min-width: 700px)"
            srcSet={ipad}
          />
          <source media="(min-width: 1100px)" srcSet={big} />
          <img src={big} alt="Marketing" />
        </picture>
      </div>
      <div className={classes.intoContainer}>
        <h1>Our Services</h1>
        <hr className={classes.line} />
        <p>
          We provide 360• marketing solutions, helping businesses grow, expand
          and flourish like never before!
        </p>
      </div>
      <div className={classes.services}>
        <div className={classes.oneService}>
          <RightServiceContainer
            heading="Digital Marketing "
            text="In the age of digitalisation, next AD has created benchmarks, both in terms of numbers and innovation. We transform your digital presence into an unforgettable one, helping you spread awareness, promote your product/service and achieve incredible sales."
            image={require("../../Images/Nextad-Web-Services-Digital.png")}
            phone={require("../../Images/Nextad-Web-Photography.png")}
            ipad={require("../../Images/surface-exSKJMg-_vI-unsplash.jpg")}
          />
        </div>
        <div className={classes.twoService}>
          <LeftServiceContainer
            heading="Branding"
            text="At next AD, we help businesses flourish into brands. We give businesses a voice, an identity and a prominent presence that cultivates numbers and success."
            image={require("../../Images/Nextad Web Services Branding.png")}
            ipad={require("../../Images/Nextad-Web-Ipad-Branding.png")}
            phone={require("../../Images/Nextad-Web-Phone-Branding.png")}
          />
        </div>
        <div className={classes.threeService}>
          <RightServiceContainer
            heading="Offline Advertising"
            text="The charm of offline advertising is timeless and ever-efficient. We provide businesses with phenomenal print solutions along with crafting eye-catching hoardings and creating buzz-making brand promotional events."
            image={require("../../Images/Nextad Web Services Offline Adv.png")}
          />
        </div>
        <div className={classes.fourService}>
          <LeftServiceContainer
            heading="Next AD Productions"
            text="We understand the power of remarkable visualisation and empower it through our production services."
            image={require("../../Images/Nextad Web Services Productions.png")}
          />
        </div>
        <div className={classes.fiveService}>
          <RightServiceContainer
            heading="Photography"
            text="We provide businesses with photography solutions, narrating brand stories through unique shoots. From fashion, product, interior, FandB shoots and more, our production ensures each picture speaks 1000 words for your brand.  "
            image={require("../../Images/Nextad-Web-Services-Photography.png")}
          />
        </div>
        <div className={classes.sixService}>
          <LeftServiceContainer
            heading="Videography"
            text="Nothing catches the eye, more than an impactful and intelligently filmed video, understanding and implementing this, we provide businesses with videography solutions ranging from fashion, product, interior, and F&B to nightlife and many more."
            image={require("../../Images/Nextad-Web-Services-Videography.png")}
          />
        </div>
        <div className={classes.fiveService}>
          <RightServiceContainer
            heading="Brand Podcast"
            text="Using and strategising on the strength of words and sound, we conceptualise, plan, script and create brand podcasts for all types of businesses and industries. "
            image={require("../../Images/Nextad-Web-Services-Podcast.png")}
          />
        </div>
      </div>
    </motion.div>
  );
};

export default OurServices;
