import classes from './Card.module.css';

const Card = (props) => {
    return (
        <div className={classes.container}>
            <img src={props.images} alt='perkImage'/>
            <hr />
            <h3>{props.heading}</h3>
            <p>{props.content}</p>
        </div>
    )
};

export default Card;