import { useState } from "react";
import CountUp from "react-countup";
import ScrollTrigger from "react-scroll-trigger";

import classes from "./Numbers.module.css";

const Numbers = () => {
  const [counterOn, setCounterOn] = useState(false);

  return (
    <ScrollTrigger
      onEnter={() => setCounterOn(true)}
      onExit={() => setCounterOn(false)}
    >
      <div className={classes.container}>
        <div className={classes.header}>
          <h1>Our Presence</h1>
          <hr />
        </div>
        <div className={classes.cardContainer}>
          <div className={classes.card}>
            <h1>
              {counterOn && (
                <CountUp start={0} end={5} duration={1} delay={0} />
              )}
              +
            </h1>
            <h5>States</h5>
          </div>
          <div className={classes.card}>
            <h1>
              {counterOn && (
                <CountUp start={0} end={99} duration={1} delay={0} />
              )}
              +
            </h1>
            <h5>Clients</h5>
          </div>
          <div className={classes.card}>
            <h1>
              {counterOn && (
                <CountUp start={0} end={20} duration={1} delay={0} />
              )}
              +
            </h1>
            <h5>Industries</h5>
          </div>
          <div className={classes.card}>
            <h1>
              {counterOn && (
                <CountUp
                  start={0}
                  separator=","
                  end={52596}
                  duration={1}
                  delay={0}
                />
              )}
            </h1>
            <h5>Brainstorming Hours</h5>
          </div>
        </div>
      </div>
    </ScrollTrigger>
  );
};

export default Numbers;
