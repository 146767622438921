import classes from "./careerCard.module.css";

const CareerCard = (props) => {
  return (
    <div className={classes.container}>
      <div className={classes.taskImage}>
        <img src={props.image} alt="employee" />
      </div>
      <div className={classes.oneContainer}>
        <div className={classes.role}>
          <h1>{props.jobRole}</h1>
        </div>
        <div className={classes.location}>
          <h4>Location:</h4>
          <h2>Jaipur</h2>
        </div>
        <div className={classes.duration}>
          <h3>Full Time</h3>
          <h4>Work From Office</h4>
          <a
            href="https://mail.google.com/mail/?view=cm&fs=1&to=careernextad@gmail.com&su=&body=Please Attach Your Resume and Portfolio link"
            target="_blank"
            rel="noopener noreferrer"
          >
            <button onClick={props.handleClick}>Apply Now</button>
          </a>
        </div>
      </div>
    </div>
  );
};

export default CareerCard;
