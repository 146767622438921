import classes from "./AboutUs.module.css";

import { motion } from "framer-motion";

const big = require("../../Images/Nextad-Web-About-us.png");
const ipad = require("../../Images/Nextad-Web-Ipad-About-Us.png");
const phone = require("../../Images/Nextad-Web-Phone-About-Us.png");

const AboutUs = () => {
  return (
    <motion.div
      intial={{ width: 0 }}
      animate={{ width: "100%" }}
      exit={{ x: window.innerWidth, transition: { duration: 0.3 } }}
    >
      <div className={classes.slideImage}>
        <picture>
          <source media="(max-width: 700px)" srcSet={phone} />
          <source
            media="(max-width: 1100px) and (min-width: 700px)"
            srcSet={ipad}
          />
          <source media="(min-width: 1100px)" srcSet={big} />
          <img src={big} alt="Marketing" crossOrigin="anonymous" />
        </picture>
      </div>
      <div className={classes.intro}>
        <h4>About Us</h4>
        <hr className={classes.line} />
        <p>
          Next AD is a hub of creativity, innovation and excellence. With over
          5+ years of building brands, surpassing targets and achieving goals,
          <br /> the company stands as the epitome of perfection in the market.
          <br />
          <br />
          Based out of the pink city, we began this creative journey as a
          content creation company that wrote stories of creativity and
          phenomenality further expanding into a one <br />
          of its kind digital marketing, branding and advertising agency.
          <br />
          <br />
          From Hospitality, Nightlife, Fashion, Jewellery and Education to
          Beauty, E-Commerce and more, next AD has proven its remarkability
          <br /> across industries.
          <br />
          <br />
          Our team is a bunch of young experienced enthusiasts that plan,
          implement and elevate brand goals each day.
          <br />
          <br />
          With creativity at our core and dreams at a high, various years,
          brands and industries later we are on this escapade to explore, expand
          and innovate further with our values and excellence intact.
        </p>
      </div>
      <div className={classes.founders}>
        <div className={classes.ourTeam}>
          <div className={classes.hello}>
            <div className={classes.method}>
              <div className={classes.methodPicture}>
                <picture>
                  <img
                    src={require("../../Images/Nextad Web Clan 01.png")}
                    alt="Marketing"
                  />
                </picture>
              </div>
              <div className={classes.methodArrow}></div>
            </div>
            <div className={classes.methodContent}>
              <h3>Path to Perfection</h3>
              <p>
                Our creative unit and creative studio encompasses, motivates and
                empowers young dreamers and achievers to take the company
                forward with their creativity and compassion.
              </p>
            </div>
          </div>
          <div className={classes.hello2}>
            <div className={classes.familyContent}>
              <h3>The Next AD Clan</h3>
              <p>
                From planning revolutionary campaigns and implementing goals to
                enjoying endless laughs and delicious feasts, we're a unit that
                celebrates and enjoys each moment of this creative journey
                together.
              </p>
            </div>
            <div className={classes.family}>
              <div className={classes.familyArrow}></div>
              <div className={classes.familyPicture}>
                <picture>
                  <img
                    src={require("../../Images/Nextad Web Clan 2.png")}
                    alt="Marketing"
                  />
                </picture>
              </div>
            </div>
          </div>
          <div className={classes.hello3}>
            <div className={classes.thinker}>
              <div className={classes.thinkerPicture}>
                <picture>
                  <img
                    src={require("../../Images/Nextad Web Clan 3.png")}
                    alt="Marketing"
                  />
                </picture>
              </div>
              <div className={classes.thinkerArrow}></div>
            </div>
            <div className={classes.thinkerContent}>
              <h3>Dreamers and Achievers</h3>
              <p>
                Over 5+ years we've cultivated and built a culture of
                discovering and empowering great minds and creative hearts,
                letting them discover and explore without boundaries.
              </p>
            </div>
          </div>
        </div>
      </div>
    </motion.div>
  );
};

export default AboutUs;
