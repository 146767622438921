const contentTestimonials = [
  {
    description:
      "It has been such a good experience working with nextAD! The professional responses, prompt service, excellent work and extraordinary creativity have been incredible! They understand the need and deliver on time. It has been an absolute delight to work with NextAD!",
    writer: "Mr Deepak Mosoun, JKJ Fashions",
  },
  {
    description:
      "When it comes to marketing our choice has always been NextAD! We began working with nextAD a few years ago and we haven't looked back! The team has been amazing in understanding our requirements, our audience and our market, they have provided us with complete marketing support, helping us further expand our brand name. It has been an outstanding experience, to say the least! ",
    writer: "Mr Krishna Mosun , JKJ Jewellers",
  },
  {
    description:
      "NextAD has been a tremendous support to us when it comes to marketing! Their work has always encompassed a ton of creativity, the team is full of zeal and passion, and gives timely responses and creative feedback. To sum up, the professionalism and passion of nextAD has been remarkable to witness!",
    writer: "Mr Satish Tiwari , Hilton",
  },
  {
    description:
      "NextAD has been one of the best decisions we have made for our business! People constantly praise us for a great online presence, the credit for it truly goes to nextAD! They are very professional, always on time, communicate promptly and have given immensely creative ideas and output for the best marketing!",
    writer: "Mr Puneet Arora, Tokio",
  },
  {
    description:
      "This is an amazing company. From meeting timelines to providing high-quality output, nextAD has always been exceptional! They have amplified our brand name, meeting and exceeding all of our expectations! To conclude they are absolutely professional and passionate to work with, it has been a wonderful experience!",
    writer: "Mr Prakash Bedi, Clarks",
  },
  {
    description:
      "Super impressed with nextAD and the quality of work they provide! Thank you nextAD for your timely support, creative ideas and efficient output! From the incredible shoots to excellent digital management marketing, my experience with nextAD has been top-notch!",
    writer: "Mr Dinesh Ghelot, Enega",
  },
];

export default contentTestimonials;
