import classes from './Intro.module.css';

const Intro = () => {
  return (
    <div className={classes.container}>
      <h1>Namaste from nextAD</h1>
      <hr />
      <p>
        We at the next AD are a group of branding, marketing and design wizards
        creating magic through extraordinary ideas, <br />
        purposeful storytelling and a whole lot of creativity.
        <br />
        <br />
        <br />
        We bridge the gap between where our clients are and where they want to
        reach through the path of excellence and experience.
        <br />
        <br />
        <br />
        Dream high, we'll help you reach higher!
      </p>
    </div>
  );
};

export default Intro;
