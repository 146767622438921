import classes from "./ServiceContainer.module.css";

const RightServiceContainer = (props) => {
  return (
    <div className={classes.rightContainer}>
      <div className={classes.rightContent}>
        <h1>{props.heading}</h1>
        <hr className={classes.breakLineRight} />
        <p>{props.text}</p>
      </div>
      <div className={classes.rightImage}>
        <picture>
          <source media="(max-width: 700px)" srcSet={props.phone} />
          <source
            media="(max-width: 1000px) and (min-width: 700px)"
            srcSet={props.ipad}
          />
          <source media="(min-width: 1000px)" srcSet={props.image} />
          <img src={props.image} alt="Marketing" />
        </picture>
      </div>
    </div>
  );
};

export default RightServiceContainer;
