import Slider from "./Slider/Slider";
import SliderSmall from "./SmallScreenSlider/SliderSmall";
import Intro from "./Intro/Intro";
import Service from "./Service/Service";
import BrandsSlide from "./BrandsSlide/BrandsSlide";
import SmallScreenBrandsSlide from "./BrandsSlide/SmallScreenBrandsSlider";
import Perks from "./Perks/Perks";
import Numbers from "./Numbers/Numbers";
import Testimonials from "./Testimonials/Testimonials";
import { useMediaQuery } from "react-responsive";

import { motion } from "framer-motion";

const Home = () => {
  const isDesktopOrSmaller = useMediaQuery({
    query: "(min-width: 700px)",
  });

  return (
    <motion.div
      intial={{ width: 0 }}
      animate={{ width: "100%" }}
      exit={{ x: window.innerWidth, transition: { duration: 0.3 } }}
    >
      {isDesktopOrSmaller ? <Slider /> : <SliderSmall />}
      <Intro />
      <Service />
      {isDesktopOrSmaller ? <BrandsSlide /> : <SmallScreenBrandsSlide />}
      <Perks />
      <Numbers />
      <Testimonials />
    </motion.div>
  );
};

export default Home;
