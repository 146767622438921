import First from "../../Images/Nextad-Phone-Logo-1.png";
import Second from "../../Images/Nextad-Phone-Logo-2.png";
import Third from "../../Images/Nextad-Phone-Logo-3.png";
import Fourth from "../../Images/Nextad-Phone-Logo-4.png";
import Fifth from "../../Images/Nextad-Phone-Logo-5.png";
import Sixth from "../../Images/Nextad-Phone-Logo-6.png";
import Seventh from "../../Images/Nextad-Phone-Logo-7.png";

const sliderImageTwo = [
  {
    title: `Logo1`,
    urls: First,
  },
  {
    title: `Logo2`,
    urls: Second,
  },
  {
    title: `Logo3`,
    urls: Third,
  },
  {
    title: `Logo4`,
    urls: Fourth,
  },
  {
    title: `Logo5`,
    urls: Fifth,
  },
  {
    title: `Logo6`,
    urls: Sixth,
  },
  {
    title: 'Logo7',
    urls: Seventh,
  }
];

export default sliderImageTwo;
