import First from "../../Images/Nextad-Web-Slider-01.png";
import Second from "../../Images/Nextad-Web-Slider-02.png";
import Third from "../../Images/Nextad-Web-Slider-03.png";

const imageSlider = [
  {
    // title: ``,
    // description: "",
    urls: First,
  },
  {
    // title: ``,
    // description: "",
    urls: Second,
  },
  {
    // title: ``,
    // description: "",
    urls: Third,
  },
];

export default imageSlider;
