import classes from './Perks.module.css';
import Card from './Card.js';

import First from "../../Images/Nextad Web Perfection.png";
import Second from "../../Images/Nextad Web Experience.png";
import Third from "../../Images/Nextad Web Creative.png";
import Fourth from "../../Images/Nextad Web Results.png";

const Perks = () => {
    return (
      <div className={classes.container}>
        <div className={classes.header}>
          <h1>Why us over Others</h1>
          <hr />
        </div>
        <div className={classes.cardHolder}>
          <Card
            images={First}
            heading="We Chase Perfection "
            content="With creativity at our core, we serve our clients an effortless experience so that they can focus on their business without facing the hustle and bustle."
          />
          <Card
            images={Second}
            heading="Effortless Experience"
            content="Want to reach newer heights? We arrange the perfect strategic staircase that you can climb effortlessly to reach greater success."
          />
          <Card
            images={Third}
            heading="The Marriage of Creativity and Strategy"
            content="Looking for innovation, creative excellence and insightful strategy? Look no further as next AD creates the perfect bond between all."
          />
          <Card
            images={Fourth}
            heading="Results don't Lie"
            content="With top brands in our kitty, the numbers, results and brands we've created are a reflection of our experience and excellence"
          />
        </div>
      </div>
    );
};

export default Perks;