import React from "react";

import classes from './Testimonials.module.css';

function Arrows({ prevSlide, nextSlide }) {
  return (
    <div className={classes.arrows}>
      <span className={classes.prev} onClick={prevSlide}>
        &#10094;
      </span>
      <span className={classes.next} onClick={nextSlide}>
        &#10095;
      </span>
    </div>
  );
}

export default Arrows;
