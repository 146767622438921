import { Routes, Route, useLocation, Navigate } from "react-router-dom";

import Home from "../Components/Home";
import AboutUs from "../Pages/AboutUs/AboutUs";
import OurServices from "../Pages/OurServices/OurServices";
import Careers from "../Pages/Careers/Careers";

import  { AnimatePresence } from 'framer-motion';

const AnimatedRoutes = (props) => {
  const location = useLocation();
  let scroller = props.scroller;
  return (
    <AnimatePresence>
      <Routes location={location} key={location.pathname}>
        <Route path="/" element={<Home />} />
        <Route path="/AboutUs" element={<AboutUs />} />
        <Route path="/OurServices" element={<OurServices />} />
        <Route path="/Careers" element={<Careers />} scroller = {scroller} />
        <Route path="*" element={<Navigate to='/'/>} />
      </Routes>
    </AnimatePresence>
  );
};

export default AnimatedRoutes;
